<template>
  <div class="card">
    <div class="card-content">
      <div class="media">
        <div class="media-left">
          <figure class="image is-64x64">
            <img
              class="is-rounded profile-image"
              :src="data.profile_image_url"
              loading="lazy"
              alt="profile image"
              @error="fallbackProfileImage"
            >
          </figure>
        </div>
        <div class="media-content">
          <h3 class="is-size-5">
            {{ data.display_name }}
          </h3>
          <p class="is-size-7">@{{ data.tag_name }}</p>
        </div>
      </div>
      <div class="topup-button">
        <b-button
          label="Topup"
          type="is-primary"
          @click="isTopupModalActive = true"
        />
      </div>
    </div>
    <b-modal
      :active.sync="isTopupModalActive"
      :full-screen="isMobile"
    >
      <topup-modal
        :data="data"
        :currency="currency"
        :packages="packages"
        :admin="admin"
        @topupVerify="topupVerify()"
      />
    </b-modal>
  </div>
</template>

<script>
import { formatNumberCommas } from '@/utils'

export default {
  name: 'UserCard',
  components: {
    TopupModal: () => import('@/components/topup/TopupModal')
  },
  props: {
    currency: {
      type: Number,
      default: 0
    },
    data: {
      type: Object,
      default: null
    },
    packages: {
      type: Array,
      default: null
    },
    admin: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isTopupModalActive: false,
      isMobile: false
    }
  },
  watch: {
    windowWidth (width) {
      if (width < 767) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
    }
  },
  mounted () {
    if (window.innerWidth < 767) {
      this.isMobile = true
    } else {
      this.isMobile = false
    }
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  methods: {
    onResize () {
      this.windowWidth = window.innerWidth
    },
    topupVerify () {
      this.$emit('topupVerify', true)
    },
    formatNumberCommas (number) {
      return formatNumberCommas(number)
    },
    fallbackProfileImage (e) {
      e.target.src = 'https://space.pillowcast.app/image/default/default.svg'
    },
    getThumbnailImage (url) {
      if (url) {
        return url
      }
      return 'https://space.pillowcast.app/image/default/logo.svg'
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  color: #ffffff;
  border: solid 1px rgba(255, 255, 255, 0.07);
  background: rgba(255, 255, 255, 0.05);
  box-shadow: none;
  a {
    color: #ffffff;
  }
  p {
    color: rgba(255, 255, 255, 0.5);
  }
}
.card-content {
  padding: 10px;
  h3 {
    line-height: 1.25rem;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
  .media-footer {
    img {
      margin-right: 3px;
      opacity: 0.75;
    }
    .stat {
      font-size: 0.75rem;
      align-items: center;
      margin-bottom: 0.5rem;
      @media screen and (max-width: 767px) {
        font-size: 0.75rem;
        margin: 0;
      }
      .icon {
        width: auto;
        margin-right: 10px;
        img {
          margin-right: 5px;
        }
      }
    }
  }
}

.topup-button {
  width: 100%;
  .button {
    width: 100%;
    color: #fff;
    font-weight: bold;
    background: #e2aa04;
    border: none;
  }
}

@keyframes glowRotate {
  100% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  0% {
    background-position: 0% 50%;
  }
}
</style>
